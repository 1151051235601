body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Sarala';
  src: url('./assets/Sarala-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'Sarala', sans-serif !important;  /* Removed !important to allow specific overrides */
  font-size: 11px;
  scrollbar-width: thin;
  scrollbar-color: #888 #e1e1e1;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Explicit font-family for code elements */
/* Styling for code elements */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  background-color: #f4f4f4; /* Light gray background */
  color: #333; /* Darker text color for contrast */
  padding: 8px; /* Padding inside the box */
  border-radius: 4px; /* Rounded corners for the box */
  border: 1px solid #ccc; /* Light gray border */
  display: block; /* Makes the code element a block to format it separately from the surrounding text */
  overflow-x: auto; /* Adds a horizontal scrollbar if the code overflows */
  white-space: pre-wrap; /* Ensures that lines do not break words inappropriately */
  word-wrap: break-word; /* Allows long words to break and wrap to the next line */
}


table {
  border-collapse: collapse;
  width: 100%;
}
/* 
table, th, td {
  border: 1px solid black;
} */

th, td {
  padding: 8px;
  text-align: left;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
  display: flex;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  z-index: 1000; /* Ensure it's above other elements */
  overflow: hidden; /* Hide overflow */
}

.modal-content {
  position: relative;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  max-width: 70%; /* Ensure it does not exceed screen width */
  max-height: 90%; /* Ensure it does not exceed screen height */
  display: flex; /* Flex container */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.modal-image {
  max-width: 50%; /* Maximum width is the viewport width */
  max-height: 90%; /* Maximum height is the viewport height */
  height: auto; /* Maintain aspect ratio */
  width: auto;  /* Maintain aspect ratio */
}

.close-button {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 15px; /* Adjust as needed */
  background-color: #fff; /* Background color */
  border: none; /* No border */
  border-radius: 50%; /* Circular shape */
  padding: 5px 10px; /* Padding around the X */
  margin-right: 25%;
  cursor: pointer; /* Cursor to pointer on hover */
  font-weight: bold; /* Bold font for the X */
  z-index: 10; /* Ensure it's above other elements */
}

.close-button:hover {
  background-color: #ddd; /* Light grey background on hover */
}

.right-modal-file {
  word-wrap: break-word;
  max-width: 65%;
}

/* Responsive Styles for Mobile Devices */
@media (max-width: 768px) {
  .modal-content {
    max-width: 100%; /* Ensure it does not exceed screen width on mobile */
    max-height: 100%;
    padding: 10px; /* Add some padding on smaller screens */
  }

  .modal-image {
    max-width: 100%; /* Maximum width is the viewport width */
    max-height: 100%; /* Maximum height is the viewport height */
    height: auto; /* Maintain aspect ratio */
    width: auto;  /* Maintain aspect ratio */
  }

  .close-button {
    position: absolute;
    top: 20px; /* Adjust as needed */
    right: 5px; /* Adjust as needed */
    background-color: #fff; /* Background color */
    border: none; /* No border */
    border-radius: 50%; /* Circular shape */
    padding: 5px 10px; /* Padding around the X */
    margin-right: 5%;
    cursor: pointer; /* Cursor to pointer on hover */
    font-weight: bold; /* Bold font for the X */
    z-index: 10; /* Ensure it's above other elements */
  }

  .right-modal-file {
    max-width: 45%;
  }
}
